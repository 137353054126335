<template>
  <!-- 允许输入正整数，调起纯数字键盘 -->
  <van-field v-model="phone" type="digit" readonly clickable required :label="str" :rules="[{ validator: validatorPhoneMessage }]"
             :placeholder="`请输入`+str"  @click="input()" />
  <van-field
      v-model="verificationCode"
      center
      required
      clearable
      type="digit"
      label="短信验证码"
      placeholder="请输入短信验证码"
      :rules="[{ validator: validatorCodeMessage }]"
      @update:model-value="changeVerificationCode"
  >
    <template #button>
      <van-button size="small" type="primary" @click="sendCerificationCode(phone)"
                  :disabled="verificationCodeButton.disabled">{{ verificationCodeButton.content }}
      </van-button>
    </template>
  </van-field>
  <van-number-keyboard class="keyboardNav"
                       title="输入手机号码"
                       v-model="phone"
                       :show="showKeyboard"
                       close-button-text="完成"
                       @close="closeKeyBoard"
                       @blur="closeKeyBoard"

                       :maxlength="11"
  />
</template>

<script>
import {reactive, toRefs} from 'vue'
import req from "../../request/request";
import API from "../../api/User";

export default {
  name: 'verificationCode',
  components: {},
  props: {
    str: {
      type: String,
      default: () => {
        return "手机号码"
      }
    }
  },
  emits:['changePhone','changeVerificationCode'],
  setup(props, context) {


    let data = reactive({
      verificationCodeButton: {content: '获取验证码', disabled: false},
      phone: '',
      verificationCode: '',
      showKeyboard: false,
    });

    const methodMap = {

      input: () => {
        data.showKeyboard = true;
      },noBomBox: () => {
        document.activeElement.blur();
      },
      closeKeyBoard: () => {
        context.emit('changePhone', data.phone)
        data.showKeyboard = false
        methodMap.validatorPhoneMessage(data.phone)
      },

      validatorPhoneMessage: (val) => {
        if (!val) {
          return '请输入手机号码'
        }
        if (!methodMap.validatorPhonePattern(val)) {
          return `${val}` + '不合法,请输入正确的手机号码'
        }
      },
      validatorPhonePattern: (val) => {
        return /^(1\d{10})$/.test(val)
      },
      validatorCodeMessage: (val) => {
        if (!val) {
          return '请输入验证码'
        }
      },
      changeVerificationCode: (val) => {
        context.emit('changeVerificationCode', val)
      },
      changePhone: (val) => {
        console.log("changePhone:",val)
        context.emit('changePhone', val)
      },
      sendCerificationCode: (phone) => {
        if (!methodMap.validatorPhonePattern(phone)) {
          return
        }
        req.request(API.getVerificationCode, {phone: data.phone}, {method: 'POST_FORMDATA'}
            , {message: '发送中...'}).then((res) => {
          if (res.data.code == 200)
            methodMap.timerCount()
        })
      },
      timerCount: () => {
        let second = 60;
        const timer = setInterval(() => {
          second--;
          if (second) {
            data.verificationCodeButton = {content: `${second} 秒`, disabled: true};
          } else {
            clearInterval(timer);
            data.verificationCodeButton = {content: '获取验证码', disabled: false};
          }
        }, 1000);
      },
    }

    return {
      ...toRefs(data),
      ...methodMap
    }
  }
}
</script>

<style lang="less" scoped>

.keyboardNav {
  --van-number-keyboard-title-font-size: 10px;
}

.vanButton {

  .vanButtonFix {
    position: fixed;
    height: 30px;
    bottom: 20px;
    width: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

</style>