<template>
  <div class="register app_content">
    <div class="warp_body">
      <div class="registerBody">
        <van-form @submit="onSubmit" v-show="showRegisterDetailItem">
          <van-cell-group inset>
            <!-- 输入任意文本 -->
            <van-field v-model="form.name" label="姓名" required placeholder="请输入姓名"
                       :rules="[{ validator: validatorPersonMessage }]"/>
            <!-- 输入手机号，调起手机号键盘 -->
            <van-field v-model="form.idCard" type="tel" required readonly clickable label="身份证号码"
                       placeholder="请输入身份证名号码"
                       @touchstart.stop="showIdCard = true" :rules="[{ validator: validatorIdCardMessage }]"/>

            <van-number-keyboard
                v-model="form.idCard"
                title="输入身份证明号码"
                :show="showIdCard"
                :maxlength="18"
                extra-key="X"
                close-button-text="完成"
                @blur="showIdCard = false"
            />

            <van-field
                v-model="form.areaText"
                is-link
                readonly
                required
                name="areaPicker"
                label="所在区域"
                placeholder="点击选择区域"
                @click="getAreaList"
                :rules="[{ validator: validatorAreaMessage }]"
            />
            <van-popup v-model:show="showAreaPicker" position="bottom">
              <van-picker
                  :columns="areaList"
                  @confirm="onConfirmArea"
                  @cancel="showAreaPicker = false"
              />
            </van-popup>
            <van-field name="radio" required label="服务类型" error-message="用工企业可通过工种需求对你进行查询下单">
              <template #input>
                <van-radio-group v-model="form.serviceType" direction="horizontal" @click="selectServiceType">
                  <div v-for=" serviceTeam in serviceList"
                       :key="serviceTeam">

                    <van-radio :name="serviceTeam.value">{{ serviceTeam.name }}</van-radio>
                  </div>

                </van-radio-group>
              </template>
            </van-field>
            <van-field
                v-model="form.workTypeText"
                is-link
                readonly
                required
                name="picker"
                label="工种"
                placeholder="点击选择工种"
                @click="getWorkTypeList"
                :rules="[{ validator: validatorWorkTypeMessage }]"
            />
            <van-popup v-model:show="showWorkTypePicker" position="bottom">
              <van-picker
                  :columns="workTypeList"
                  @confirm="onConfirmWorkType"
                  @cancel="showWorkTypePicker = false"
              />
            </van-popup>

            <van-field
                v-model="form.levelText"
                is-link
                readonly
                required
                name="picker"
                label="级别"
                placeholder="点击选择级别"
                @click="getLevelList"
                :rules="[{ validator: validatorLevelMessage }]"
            />
            <van-popup v-model:show="showLevelPicker" position="bottom">
              <van-picker
                  :columns="levelList"
                  @confirm="onConfirmLevel"
                  @cancel="showLevelPicker = false"
              />
            </van-popup>


            <van-field v-model="form.price" required label="服务价格区间" type="digit" label-width="100px"/>

            <van-field name="personalPic" label="个人照片上传" required label-width="100px"
                       :rules="[{ validator: validatorPersonImgMessage }]">
              <template #input>
                <van-uploader v-model="fileListPerson" max-count=1 :before-delete="beforeDeletePerson"
                              :after-read="afterReadPersonImg"/>
              </template>
            </van-field>

            <van-field name="certificationPic" label="工人资质上传" label-width="100px" required
                       :rules="[{ validator: validatorWorkPermitImgMessage }]">
              <template #input>
                <van-uploader max-count=1 v-model="fileListWorkPermit" :before-delete="beforeDeleteWorkPermit"
                              :after-read="afterReadWorkPermitImg"/>
              </template>
            </van-field>

            <!-- 允许输入正整数，调起纯数字键盘 -->
            <!--            <van-field v-model="form.phone" required type="tel" label="手机号码" placeholder="请输入手机号码"-->
            <!--                       :rules="[{ validator: validatorPhoneMessage }]"/>-->

            <!--            <van-field-->
            <!--                v-model="form.verificationCode"-->
            <!--                center-->
            <!--                clearable-->
            <!--                required-->
            <!--                label="短信验证码"-->
            <!--                placeholder="请输入短信验证码"-->
            <!--                :rules="[{ validator: validatorCodeMessage }]"-->
            <!--            >-->
            <!--              <template #button>-->
            <!--                <van-button size="small" type="primary" :disabled="verificationCodeButton.disabled" @click="sendCerificationCode">{{-->
            <!--                    verificationCodeButton.content-->
            <!--                  }}-->
            <!--                </van-button>-->
            <!--              </template>-->
            <!--            </van-field>-->

            <van-field label="接单状态" readonly required>
              <template #right-icon>
                <van-switch v-model="form.orderStatus" size="24"/>
              </template>
            </van-field>

            <van-field v-model="form.companyName" label-width="95px" label="所属单位名称" placeholder="请输入所属单位名称"
            />
            <van-field v-model="form.companyCreditCode" label-width="95px" label="单位信用代码" placeholder="请输入单位信用代码"
            />
            <verification-code
                @changeVerificationCode="changeVerificationCode" @changePhone="changePhone"></verification-code>
          </van-cell-group>

          <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit">
              注册
            </van-button>
          </div>
        </van-form>
        <van-form @submit="onSubmit2" v-show="!showRegisterDetailItem">
          <van-cell-group inset>
            <!-- 输入任意文本 -->
            <van-field v-model="form.name" label="姓名" required placeholder="请输入姓名"
                       :rules="[{ validator: validatorPersonMessage }]"/>
            <!-- 输入手机号，调起手机号键盘 -->
            <van-field v-model="form.idCard" type="tel" required readonly clickable label="身份证号码"
                       placeholder="请输入身份证名号码"
                       @touchstart.stop="showIdCard = true" :rules="[{ validator: validatorIdCardMessage }]"/>

            <van-number-keyboard
                v-model="form.idCard"
                title="输入身份证明号码"
                :show="showIdCard"
                :maxlength="18"
                extra-key="X"
                close-button-text="完成"
                @blur="showIdCard = false"
            />

            <van-field
                v-model="form.areaText"
                is-link
                readonly
                required
                name="areaPicker"
                label="所在区域"
                placeholder="点击选择区域"
                @click="getAreaList"
                :rules="[{ validator: validatorAreaMessage }]"
            />
            <van-popup v-model:show="showAreaPicker" position="bottom">
              <van-picker
                  :columns="areaList"
                  @confirm="onConfirmArea"
                  @cancel="showAreaPicker = false"
              />
            </van-popup>
            <van-field name="radio" required label="服务类型" error-message="平台部分功能无法使用">
              <template #input>
                <van-radio-group v-model="form.serviceType" direction="horizontal" @click="selectServiceType">
                  <div v-for=" serviceTeam in serviceList"
                       :key="serviceTeam">
                    <van-radio :name="serviceTeam.value">{{ serviceTeam.name }}</van-radio>
                  </div>

                </van-radio-group>
              </template>
            </van-field>
            <verification-code
                @changeVerificationCode="changeVerificationCode" @changePhone="changePhone"></verification-code>
          </van-cell-group>

          <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit">
              注册
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, toRefs} from 'vue'
import req from "../request/request";
import API from "../api/User";
import router from "../router";
import {Toast} from "vant";
import verificationCode from '../components/common/verificationCode'
import {useRouter} from 'vue-router'
import {compress, compressAccurately} from 'image-conversion'

export default {
  name: 'Register',
  components: {verificationCode},

  setup() {

    onMounted(() => {
      let registerType = JSON.parse(sessionStorage.getItem("registerType"))
      let pathRoute = useRouter();
      console.log(pathRoute.currentRoute.value.path)
      if (registerType.route != pathRoute.currentRoute.value.path) {
        Toast("不合法的访问！")
        router.push("/")
      }
    })


    const data = reactive({
          showIdCard: false,
          verificationCodeButton: {content: '获取验证码', disabled: false},
          serviceList: [{name: "固定工种", value: 1,}, {name: "非固定工种", value: 0,}],
          fileListPerson: [],
          fileListWorkPermit: [],
          showRegisterDetailItem: true,
          showAreaPicker: false,
          showWorkTypePicker: false,
          showLevelPicker: false,
          servceTypeCheck: '',
          areaList: [],
          workTypeList: [],
          levelList: [],
          form: {
            name: '',
            idCard: '',
            phone: '',
            areaId: '',
            areaText: '',
            levelId: '',
            level: '',
            levelText: '',
            price: '',
            verificationCode: '',
            workTypeId: '',
            workType: '',
            serviceType: 1,
            workTypeText: '',
            personImg: '',
            workPermitImg: '',
            companyName: '',
            companyCreditCode: '',
            orderStatus: true,
            openid: localStorage.getItem("openid")
          },
          inputErrorText: {
            name: '',
            idCard: '',
            area: '',
            workType: '',
            phone: '',
            level: '',
            price: '',
            verificationCode: '',
            personImg: '',
            workPermitImg: '',
          },

        },
    )
    // // 校验函数返回 true 表示校验通过，false 表示不通过
    // const validator = (val) => /1\d{10}/.test(val);
    //
    // // 校验函数可以直接返回一段错误提示
    // const validatorMessage = (val) => `${val} 不合法，请重新输入`;

    const methodsMap = {
      selectServiceType: () => {
        if (data.form.serviceType == 0) {
          data.showRegisterDetailItem = false;
          data.form.workTypeId = '';
          data.form.level = '';
          data.form.price = '';
          data.form.personImg = '';
          data.form.workPermitImg = '';
          data.form.orderStatus = '';
          data.form.companyCreditCode = '';
          data.form.companyName = '';
        } else if (data.form.serviceType == 1) {
          data.showRegisterDetailItem = true;
        }

      },
      changePhone: (val) => {
        console.log("phone val:", val)
        data.form.phone = val
      },
      changeVerificationCode: (val) => {
        data.form.verificationCode = val
      },
      validatorPersonMessage: (val) => {
        if (!val) {
          return '请输入姓名'
        }
      },
      validatorIdCardMessage: (val) => {
        if (!val) {
          return '请输入身份证明号码'
        }
        if (!/^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(val)) {
          return `${val}` + '不合法,请输入正确的身份证明号码'
        }
      },
      validatorPhoneMessage: (val) => {
        if (!val) {
          return '请输入手机号码'
        }
        if (!methodsMap.validatorPhonePattern(val)) {
          return `${val}` + '不合法,请输入正确的手机号码'
        }
      },
      validatorPhonePattern: (val) => {
        return /^(1\d{10})$/.test(val)
      },
      validatorCodeMessage: (val) => {
        if (!val) {
          return '请输入验证码'
        }
      },
      validatorAreaMessage: (val) => {
        if (!val) {
          return '请选择所在区域'
        }
      },
      validatorWorkTypeMessage: (val) => {
        if (!val) {
          return '请选择工种'
        }
      },
      validatorLevelMessage: (val) => {
        if (!val) {
          return '请选择级别'
        }
      }, validatorPersonImgMessage: () => {
        if (data.fileListPerson.length == 0) {
          return '请上传个人照片'
        }
      }, validatorWorkPermitImgMessage: () => {
        if (data.fileListWorkPermit.length == 0) {
          return '请上传资质凭证'
        }
      },
      sendCerificationCode: () => {
        if (!methodsMap.validatorPhonePattern(data.form.phone)) {
          return
        }
        req.request(API.getVerificationCode, {phone: data.form.phone}, {method: 'POST_FORMDATA'}
            , {message: '发送中...'}).then(() => {
          methodsMap.timerCount()
        })
      },
      timerCount: () => {
        let second = 60;
        const timer = setInterval(() => {
          second--;
          if (second) {
            data.verificationCodeButton = {content: `${second} 秒`, disabled: true};
          } else {
            clearInterval(timer);
            data.verificationCodeButton = {content: '获取验证码', disabled: false};
          }
        }, 1000);
      },
      onConfirm: () => {
      },
      onSubmit: () => {
        // let form = data.form
        // console.log('submit', form)
        // if (!form.name) {
        //   data.inputErrorText.name = '请输入姓名'
        // }
        // if (!form.idCard.match(/^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/)) {
        //   data.inputErrorText.idCard = '请输入正确的身份证明号码'
        // }
        // if (!form.idCard) {
        //   data.inputErrorText.idCard = '请输入身份证明号码'
        // }
        // if (!form.area) {
        //   data.inputErrorText.area = '请选择所在区域'
        // }
        // if (!form.workType) {
        //   data.inputErrorText.workType = '请选择工种'
        // }
        // if (!form.level) {
        //   data.inputErrorText.level = '请选择级别'
        // }
        //
        // if (!form.phone.match('/^(1\\d{10})$/')) {
        //   data.inputErrorText.phone = '手机号码不合法，请输入正确的手机号码'
        // }
        // if (!form.phone) {
        //   data.inputErrorText.phone = '请输入手机号码'
        // }
        // if (data.fileListPerson.length == 0) {
        //   data.inputErrorText.personImg = '请上传个人照片'
        // }
        // if (data.fileListWorkPermit.length == 0) {
        //   data.inputErrorText.workPermitImg = '请上传工作资质照片'
        // }
        let params = data.form
        // params.personImg = data.fileListPerson[0].file
        // params.workPermitImg = data.fileListWorkPermit[0].file
        req.request(API.register + "/" + JSON.parse(sessionStorage.getItem("registerType")).code, params,
            {method: 'POST_FORMDATA'}, {message: '注册中...'})
            .then((res) => {
              if (res.data.code == 200) {
                router.push('/GetUserInfo')
                Toast.success("注册成功！")
              }
            })

      },
      onSubmit2: () => {
        // let form = data.form
        // console.log('submit', form)
        // if (!form.name) {
        //   data.inputErrorText.name = '请输入姓名'
        // }
        // if (!form.idCard.match(/^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/)) {
        //   data.inputErrorText.idCard = '请输入正确的身份证明号码'
        // }
        // if (!form.idCard) {
        //   data.inputErrorText.idCard = '请输入身份证明号码'
        // }
        // if (!form.area) {
        //   data.inputErrorText.area = '请选择所在区域'
        // }
        // if (!form.workType) {
        //   data.inputErrorText.workType = '请选择工种'
        // }
        // if (!form.level) {
        //   data.inputErrorText.level = '请选择级别'
        // }
        //
        // if (!form.phone.match('/^(1\\d{10})$/')) {
        //   data.inputErrorText.phone = '手机号码不合法，请输入正确的手机号码'
        // }
        // if (!form.phone) {
        //   data.inputErrorText.phone = '请输入手机号码'
        // }
        // if (data.fileListPerson.length == 0) {
        //   data.inputErrorText.personImg = '请上传个人照片'
        // }
        // if (data.fileListWorkPermit.length == 0) {
        //   data.inputErrorText.workPermitImg = '请上传工作资质照片'
        // }
        let params = data.form
        // params.personImg = data.fileListPerson[0].file
        // params.workPermitImg = data.fileListWorkPermit[0].file
        req.request(API.register + "/" + JSON.parse(sessionStorage.getItem("registerType")).code, params,
            {method: 'POST_FORMDATA'}, {message: '注册中...'})
            .then((res) => {
              if (res.data.code == 200) {
                router.push('/GetUserInfo')
                Toast.success("注册成功！")
              }
            })

      },
      afterReadPersonImg: (file) => {
        console.log(file.file)
        console.log(data.fileListPerson[0].file)

        new Promise((resolve) => {
          compress(file.file, 0.6)
              .then(res => {
                console.log(res)
                data.form.personImg = new window.File([res], file.file.name, {type: file.file.type})
                // new window.File([resolve(res),file.file.name,{type:file.file.type}])
              });
        })
        // data.form.personImg = new window.File([compress(file.file,0.5),file.file.name,{type:file.file.type}])
      },
      afterReadWorkPermitImg: (file) => {
        console.log('sss', file.file)
        console.log('sss', data.fileListWorkPermit[0].file)
        // data.form.workPermitImg = compress(data.fileListWorkPermit[0].file, 0.5)
        new Promise((resolve) => {
          compress(file.file, 0.6)
              .then(res => {
                console.log(res)
                data.form.workPermitImg = new window.File([res], file.file.name, {type: file.file.type})
                // new window.File([resolve(res),file.file.name,{type:file.file.type}])
              });
        })
      },
      beforeDeletePerson: () => {
        data.fileListPerson = []
        data.form.personImg = ''
      },
      beforeDeleteWorkPermit: () => {
        data.fileListWorkPermit = []
        data.form.workPermitImg = ''
      },
      getAreaList: () => {
        data.showAreaPicker = true
        req.request(API.areaList, null, {method: 'GET'})
            .then((res) => {
              data.areaList = res.data.data
            })
      },
      getLevelList: () => {
        data.showLevelPicker = true
        req.request(API.levelList, null, {method: 'GET'})
            .then((res) => {
              data.levelList = res.data.data
            })
      },
      getWorkTypeList: () => {
        data.showWorkTypePicker = true
        req.request(API.workTypeList, null, {method: 'GET'})
            .then((res) => {
              data.workTypeList = res.data.data
            })
      },
      onConfirmArea: (item) => {
        data.showAreaPicker = false
        data.form.areaText = item.text
        data.form.areaId = item.id
      },
      onConfirmWorkType: (item) => {
        data.showWorkTypePicker = false
        data.form.workTypeText = item.text
        data.form.workType = item.value
        data.form.workTypeId = item.id
      },
      onConfirmLevel: (item) => {
        data.showLevelPicker = false
        data.form.levelText = item.text
        data.form.level = item.value
        data.form.price = item.price
        data.form.levelId = item.id
      },
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less">
.app_content {
  .registerBody {
    margin-top: 5px;
    --van-number-keyboard-title-font-size: 10px;
  }
}
</style>
